import React from 'react'
import styled from 'styled-components';

import Button from '../../_atoms/Button';
import CTANav from '../../_molecules/CTANav';
import Hero from '../../_molecules/Hero';

const Wrap = styled.div`
	display: flex;
	background-color: black;
	width: 100%;

	sub {
		bottom: 0;
	}
`;

function HomeTop() {
	return (
		<Wrap>
			<CTANav />
			<Hero
				bg='portal-masthead-2023-AA.jpg'
				bgPreview='compressed-portal-masthead-2023-AA.jpg'
				size='lg'
				className='ram2022'
			>
				<h1>
					NEW OFFERINGS. <span>NEW OPPORTUNITIES.</span>
				</h1>
				<footer>
					<Button
						target='_blank'
						href='https://firebasestorage.googleapis.com/v0/b/mopar-portal-production.appspot.com/o/assets%2Fawj7TewKp0CDIa95mmZV%2Ffile-forPreview?alt=media&token=941a30a4-85e9-4e8c-ad11-6cf66fbaeeb6'
						el='a'
						variant='alternate'
					>
						More Info
					</Button>
					{/* access guide */}
					<Button
						target='_blank'
						href='https://firebasestorage.googleapis.com/v0/b/mopar-portal-production.appspot.com/o/assets%2FIimGuzkunA52Ba9awFw0%2Ffile-forPreview?alt=media&token=541993a8-7bbc-4a5e-993e-0c493babe6a7'
						el='a'
						variant='alternate'
					>
						Access Guide
					</Button>
				</footer>
			</Hero>
		</Wrap>
	);
}

export default HomeTop;
